import { formatNameToURL, formatWeight } from "./formatters";
import _ from "lodash";
import i18n from "../translations/i18n";

// get product discount price
export const getDiscountPrice = (price, discount) => {
  return discount && discount > 0 ? price - price * (discount / 100) : null;
};

// get product discount price with default
export const getDiscountPriceWithDefault = (price, discount) => {
  return discount && discount > 0 ? price - price * (discount / 100) : price;
};

// get product cart quantity
export const getProductCartQuantity = (cartItems, product) => {
  let productInCart = cartItems.filter((single) => single.id === product.id)[0];
  if (cartItems.length >= 1 && productInCart) {
    return cartItems.filter((single) => product.id === single.id)[0].quantity;
  } else {
    return 0;
  }
};

//get products based on category
export const getSortedProducts = (products, sortType, sortValue) => {
  if (products && sortType && sortValue) {
    if (sortType === "category") {
      return products.filter(
        (product) =>
          product.category.filter(
            (single) =>
              formatNameToURL(single.toLowerCase()) === sortValue.toLowerCase()
          )[0]
      );
    }
    if (sortType === "tag") {
      return products.filter(
        (product) => product.tag.filter((single) => single === sortValue)[0]
      );
    }
    if (sortType === "color") {
      return products.filter(
        (product) =>
          product.variation &&
          product.variation.filter((single) => single.color === sortValue)[0]
      );
    }
    if (sortType === "size") {
      return products.filter(
        (product) =>
          product.variation &&
          product.variation.filter(
            (single) =>
              single.size.filter((single) => single.name === sortValue)[0]
          )[0]
      );
    }
    if (sortType === "filterSort") {
      let sortProducts = [...products];
      if (sortValue === "default") {
        return sortProducts;
      }
      if (sortValue === "priceHighToLow") {
        return sortProducts.sort((a, b) => {
          return b.price - a.price;
        });
      }
      if (sortValue === "priceLowToHigh") {
        return sortProducts.sort((a, b) => {
          return a.price - b.price;
        });
      }
    }
  }
  return products;
};

export const sortProductsByPopular = (products) => {
  return _.orderBy(products, ({ purchased }) => purchased || 0, ["desc"]);
};

export const sortProductsByFeatured = (
  products,
  featuredLists,
  featuredType
) => {
  let currentList =
    !!featuredLists && featuredLists.find((item) => item.type === featuredType);
  if (
    !!currentList &&
    !!currentList.products &&
    currentList.products.length > 0
  ) {
    // Sort products by whether they are included in the featured list or not
    return _.sortBy(products, [
      (item) => {
        let index = _.findIndex(currentList.products, (id) => id === item.id);
        // If product is not found, modify index to be bigger than the featured products because
        // we sort the entire list by ascending order
        if (index === -1) index = index + products.length + 1;
        return index;
      },
    ]);
  } else return products;
};

// get individual element
const getIndividualItemArray = (array) => {
  return array.filter(function (v, i, self) {
    return i === self.indexOf(v);
  });
};

// get individual categories
export const getIndividualCategories = (products) => {
  let productCategories = [];
  products &&
    products.map((product) => {
      return (
        product.category &&
        product.category.map((single) => {
          return productCategories.push(single);
        })
      );
    });
  return getIndividualItemArray(productCategories);
};

export const setActiveSort = (e) => {
  const filterButtons = document.querySelectorAll(
    ".sidebar-widget-list-left button, .sidebar-widget-tag button, .product-filter button"
  );
  filterButtons.forEach((item) => {
    item.classList.remove("active");
  });
  if (!!e.currentTarget) e.currentTarget.classList.add("active");
};

export const setActiveLayout = (e) => {
  const gridSwitchBtn = document.querySelectorAll(".shop-tab button");
  gridSwitchBtn.forEach((item) => {
    item.classList.remove("active");
  });
  e.currentTarget.classList.add("active");
};

/**
 * Get variant price if product has variant price, else return product price
 * @param {*} item
 * @returns price
 */
export const getVariantWeightPrice = (item) => {
  return item.variation && item.variation.weight
    ? item.variation.price
    : item.price;
};

/**
 * Show formatted product weight with unit. To display behind product.
 * E.g. Product x (100 gram)
 * @param cartItem cartItem
 * @param t translation
 * @returns formatted weight
 */
export const showProductWeight = (cartItem, t) => {
  return cartItem.variation && cartItem.variation.weight
    ? "(" + formatWeight(cartItem.variation.weight) + " " + t("gram") + ")"
    : "";
};

/**
 * Show formatted product type. To display behind product name.
 * E.g. Product x (marinated)
 * @param product
 * @returns {string|null}
 */
export const showProductType = (product) => {
  return product.type ? "(" + product.type + ")" : null;
};

/**
 * Get product modal text used in My Orders to re-oder a order
 * @param changedProductList
 * @param notAvailableProductList
 * @returns {string}
 */
export const formatProductModalText = (
  changedProductList,
  notAvailableProductList
) => {
  if (
    changedProductList &&
    changedProductList.length > 0 &&
    notAvailableProductList === null
  ) {
    if (changedProductList.length > 1) {
      return i18n.t("modal.product_price_changed_plural");
    } else {
      return i18n.t("modal.product_price_changed");
    }
  } else if (
    notAvailableProductList &&
    notAvailableProductList.length > 0 &&
    changedProductList === null
  ) {
    if (notAvailableProductList.length > 1) {
      return i18n.t("modal.product_not_available_plural");
    } else {
      return i18n.t("modal.product_not_available");
    }
  } else if (
    changedProductList &&
    changedProductList.length > 0 &&
    notAvailableProductList &&
    notAvailableProductList.length > 0
  ) {
    return i18n.t("modal.product_has_changed_plural");
  }
};

/**
 * Filter products with searched value
 * @param products list of products
 * @param value searched value
 * @returns filtered product list
 */
export const searchProducts = (products, value) => {
  return products.filter(
    (product) =>
      product.name
        .toString()
        .toUpperCase()
        .indexOf(value.toString().toUpperCase()) !== -1
  );
};

/**
 * Check if product has minimum amount
 * If so set the minimum quantity to that amount
 * @param minAmount Product minimum amount
 * @param quantityCount The selected quantity
 * @returns {number|number|*}
 */
export const checkMinAmount = (minAmount, quantityCount) => {
  if (minAmount) {
    return quantityCount > minAmount ? quantityCount - 1 : minAmount;
  } else {
    return quantityCount > 1 ? quantityCount - 1 : 1;
  }
};

/**
 * Filter cart items depending on the product. Used in cart reducer.
 * @param cartItems
 * @param product
 * @returns {*}
 */
export const filterProduct = (cartItems, product) => {
  if (product.variation && !product.type) {
    return cartItems.filter(
      (item) =>
        item.id === product.id &&
        item.variation &&
        product.variation.weight &&
        item.variation.weight === product.variation.weight
    )[0];
  } else if (product.type && !product.variation) {
    return cartItems.filter(
      (item) =>
        item.id === product.id && !!item.type && item.type === product.type
    )[0];
  } else if (product.variation && product.type) {
    return cartItems.filter(
      (item) =>
        item.id === product.id &&
        item.variation &&
        item.type &&
        item.variation.weight === product.variation.weight &&
        item.type === product.type
    )[0];
  } else {
    return cartItems.filter((item) => item.id === product.id)[0];
  }
};

/**
 * Find purchased product in CMS products to find the current variant of the product
 * (Product properties might have changed)
 * If there are changes in the purchased product and cms product, it will return nothing
 * @param productToFind
 * @param productList
 * @returns {*}
 */
export const findProduct = (productToFind, productList) => {
  if (productToFind.variation && !productToFind.type) {
    return productList.find(
      (product) =>
        product.id === productToFind.productId &&
        product.stock > 0 &&
        productToFind.variation.weight &&
        product.variations &&
        product.variations.filter(
          (variation) =>
            variation.type === "WEIGHT" &&
            variation.weight === productToFind.variation.weight
        ).length > 0
    );
  } else if (productToFind.type && !productToFind.variation) {
    return productList.find(
      (product) =>
        product.id === productToFind.productId &&
        product.stock > 0 &&
        !!product.type &&
        product.type === productToFind.type
    );
  } else if (productToFind.variation && productToFind.type) {
    return productList.find(
      (product) =>
        product.id === productList.id &&
        product.stock > 0 &&
        product.type &&
        product.type === productList.type &&
        productToFind.variation.weight &&
        product.variations &&
        product.variations.filter(
          (variation) =>
            variation.type === "WEIGHT" &&
            variation.weight === productToFind.variation.weight
        ).length > 0
    );
  } else {
    return productList.find(
      (product) => product.id === productToFind.productId && product.stock > 0
    );
  }
};

/**
 * Check if product has variation
 * @param cmsProduct
 * @param orderProduct
 * @returns {boolean}
 */
export const hasProductVariation = (cmsProduct, orderProduct) => {
  if (!!orderProduct.variation && orderProduct.variation.weight) {
    return (
      cmsProduct.variations.filter(
        (variation) =>
          variation.weight === orderProduct.variation.weight &&
          variation.price === orderProduct.variation.price
      ).length > 0
    );
  }
};

/**
 * Find the puchased product variation from cms product
 * @param cmsProduct
 * @param orderProduct
 * @returns {*}
 */
export const findProductVariation = (cmsProduct, orderProduct) => {
  if (!!orderProduct.variation && orderProduct.variation.weight) {
    return cmsProduct.variations.filter(
      (variation) => variation.weight === orderProduct.variation.weight
    )[0];
  } else if (cmsProduct.variations) {
    return cmsProduct.variations[0];
  }
};

/**
 * Find the puchased product type from cms product
 * @param cmsProduct
 * @param orderProduct
 * @returns {*}
 */
export const findProductType = (cmsProduct, orderProduct) => {
  if (!!orderProduct.type && !!cmsProduct.type && orderProduct.type) {
    return cmsProduct.type.filter((item) => item === orderProduct.type)[0];
  } else if (cmsProduct.type) {
    return cmsProduct.type[0];
  }
};

/**
 * Filter product by the hide variant property, used in Shoplist
 * @param products
 * @returns products filtered by the hideVariant property
 */
export const filterHiddenProducts = (products) => {
  return products.filter((product) => !product.hideVariant);
};

export const isAlwaysIncluded = (item, rentmanMaterialCode) => {
  const regex = /[a-z]/i;
  const uniquePart = item.code.split(rentmanMaterialCode)[1];
  if (!uniquePart) return false;
  const slug = uniquePart.split(")").pop(); // The category of some items stands in between (). Get only the part after this
  return regex.test(slug) && !uniquePart.includes("PERSONEEL");
};

export const isHourlyRate = (item) => {
  return item.code.includes("PERSONEEL");
};

export const getHourlyRateItem = (items) => {
  if (!!items) {
    return items.find((item) => isHourlyRate(item));
  } else return null;
};
