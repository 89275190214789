import orderId from "order-id";

/**
 * Generate orderId with Company prefix
 * @param date
 * @returns generated Order ID
 */
export const generateOrderId = (date) => {
  return orderId(process.env.REACT_APP_ORDER_ID_SECRET).generate(date);
};
